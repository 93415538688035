import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":"Chỉnh sửa bài nộp","max-width":"700","footer":null},on:{"cancel":_vm.handleCancel},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c(VContainer,[_c(VForm,[_c('h5',[_vm._v(_vm._s(_vm.exerciseData.exerciseName))]),_c('p',[_vm._v(_vm._s(_vm.exerciseData.description))]),_c(VTextField,{attrs:{"type":"text","label":"Tên file nộp bài","messages":[
          'Vui lòng chỉ thay đổi tên file không thay đổi đuôi file' ]},model:{value:(_vm.fileName),callback:function ($$v) {_vm.fileName=$$v},expression:"fileName"}}),_c(VFileInput,{attrs:{"label":"Upload File","chips":""},on:{"change":_vm.chooseImg}}),(_vm.loading)?_c('a-progress',{attrs:{"percent":_vm.uploadValue}}):_vm._e(),(_vm.uploadValue > 0 || _vm.picture !== null)?_c('DisplayFile',{attrs:{"picture":_vm.picture,"fileName":_vm.fileName,"extensionFile":null}}):_c('DisplayFile',{attrs:{"picture":_vm.answerFile,"fileName":_vm.fileName,"extensionFile":_vm.assignmentInfo&&_vm.assignmentInfo[0].fileExtension}}),_c(VBtn,{staticClass:"mr-4",attrs:{"dark":"","color":"primary","loading":_vm.loading},on:{"click":_vm.submitExercise}},[_vm._v(" Nộp ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }